// BM: no actual components yet, just moving code out of routing.js
import * as pwMap from '../map.js';
import {isOffshoreApp} from '../../platform.js';

export function makeCircleImageDataUrl(colour, radius, opacity) {
    try {
        const ratio = window.devicePixelRatio || 1;
        const canvas = document.createElement("canvas");
        canvas.width = radius * 2 * ratio;
        canvas.height = radius * 2 * ratio;
        const context = canvas.getContext("2d");
        context.scale(ratio, ratio);
        context.beginPath();
        context.arc(radius, radius, radius - 1, 0, 2 * Math.PI, false);
        context.fillStyle = colour;
        context.fill();
        // Use thinner outlines on retina
        context.lineWidth = 1 / ratio;
        context.strokeStyle = "black";
        context.globalAlpha = opacity ? opacity : 1;
        context.stroke();
        return canvas.toDataURL("image/png");
    } catch (ex) {
        return STATIC_URL + 'images/gmap_marker_route7.png'; // white
    }
}

export function makeMarkerIcon(imageUrl, className, size) {
    if (size === undefined) {
        if (window.pwMapDefaultAPI === 'atlas') {
            size = 16;
        } else {
            size = 10;
        }
    }
    return pwMap.icon({
        img: imageUrl,
        className: className,
        width: size,
        height: size,
        anchorX: size / 2,
        anchorY: size / 2
    });
}

function isValidCoord(txt) {
    return !!pwMap.utils.parseCoords(txt);
}

function boundaryDataUpdated() {
    $j('pw-waypoints').trigger('boundary-data-updated');
}

class BoundaryDatasourcePrivate {
    constructor() {
        this.boundariesData = {};
        this.callbacks = {
            addedBoundary: $j.Callbacks('unique memory'),
            removingBoundary: $j.Callbacks('unique memory')
        };
    }

    saveBoundaries() {
        this._nextBoundariesData = $j.extend(true, {}, this.boundariesData);
        this.saveBoundariesData();
    }

    saveBoundariesDelay() {
        return Math.max(100, (this._saveBoundariesStartTime || 0) + 2000 - Date.now());
    }
    saveBoundariesData() {
        if (!this._saveBoundariesHandle) {
            this._saveBoundariesHandle = setTimeout(() => this.saveBoundariesDataCore(), this.saveBoundariesDelay());
            this._saveBoundariesStartTime = Date.now();
        }
    }
    saveBoundariesDataCore() {
        const boundariesData = this._nextBoundariesData;
        if (!isOffshoreApp) {
            this.uploadBoundariesData(boundariesData);
        } else {
            app.setData('RouteBoundaries', boundariesData, 0, 'BoundaryEditor');
            this.savedBoundariesData(boundariesData, null);
        }
    }
    downloadBoundariesData() {
        $j.ajax({
            type: 'GET',
            url: window.userBoundariesUrl,
            data: {},
            success: (newBoundariesData) => this.setBoundariesData(newBoundariesData),
            dataType: 'json',
            headers: {'X-CSRFToken': window.userBoundariesToken}
        });
    }
    uploadBoundariesData(boundariesData) {
        $j.ajax({
            type: 'POST',
            url: window.userBoundariesUrl,
            data: {
                boundariesJSON: JSON.stringify(boundariesData),
            },
            success: (newBoundariesData) => this.savedBoundariesData(boundariesData, newBoundariesData),
            error: () => this.savedBoundariesData(boundariesData, null),
            dataType: 'json',
            headers: {'X-CSRFToken': window.userBoundariesToken}
        });
    }

    savedBoundariesData(uploadedBoundariesData, newBoundariesData) {
        const successful = !!newBoundariesData;
        if (successful) {
            // cleanup deleted boundaries
            for (let key of Object.keys(uploadedBoundariesData)) {
                const boundaryData = this.boundariesData[key];
                if (boundaryData && boundaryData.state === 'deleted') {
                    delete this.boundariesData[key];
                }
            }
            this.setBoundariesData(newBoundariesData);
        }
        this._saveBoundariesHandle = null;
        if (this._nextBoundariesData !== uploadedBoundariesData) {
            this.saveBoundariesData();
        }
    }

    boundariesDataListener(receivedData, receivedTimestamps, notifier){
        if ('RouteBoundaries' in receivedData && notifier !== 'BoundaryEditor') {
            this.setBoundariesData(receivedData['RouteBoundaries']);
        }
    }

    addBoundaryData(key, newBoundaryData) {
        this.boundariesData[key] = newBoundaryData;
        this.callbacks.addedBoundary.fire(key, newBoundaryData);
    }

    removeBoundaryData(key) {
        this.callbacks.removingBoundary.fire(key);
        delete this.boundariesData[key];
    }

    repairBoundaryData(newBoundaryData) {
        // remove duplicate points caused by an event handler bug
        const lats = newBoundaryData.lat;
        const lons = newBoundaryData.lon;
        const len0 = lats.length;
        for (let i = lats.length - 1; i >= 0; i--) {
            const iNext = (i + 1) % lats.length;
            if (lats[i] == lats[iNext] && lons[i] == lons[iNext]) {
                lats.splice(i, 1);
                lons.splice(i, 1);
            }
        }
        return len0 != lats.length;
    }

    setBoundariesData(boundariesData) {
        boundariesData = boundariesData || {};
        for (let key of Object.keys(this.boundariesData)) {
            if (!boundariesData[key] && this.boundariesData[key].state !== 'created') {
                this.removeBoundaryData(key);
            }
        }
        let repaired = false;
        for (let key of Object.keys(boundariesData)) {
            let newBoundaryData = boundariesData[key];
            let oldBoundaryData = this.boundariesData[key];
            if (oldBoundaryData && oldBoundaryData.timestamp >= newBoundaryData.timestamp) {
                continue; // new boundary is actually old
            }
            if (newBoundaryData.state === 'created'){ // legacy check
                delete newBoundaryData.state;
            }
            this.removeBoundaryData(key);
            if (newBoundaryData.state === 'deleted') { // legacy check
                continue;
            }
            if (this.repairBoundaryData(newBoundaryData)) {
                repaired = true;
                // skip degenerate boundaries
                if (newBoundaryData.lat.length < 3) {
                    continue;
                }
            }
            this.addBoundaryData(key, newBoundaryData);
        }
        if (repaired) {
            this.saveBoundaries();
        }
        boundaryDataUpdated();
    }
}

export class BoundaryDatasource extends BoundaryDatasourcePrivate {
    initialise() {
        const that = this;
        if (isOffshoreApp) {
            app.requestData(['RouteBoundaries'], true); // subscribe
            app.addReceivedDataListener(function(){ return that.boundariesDataListener.apply(that, arguments); });
        } else {
            this.downloadBoundariesData();
        }
    }

    getBoundaryData(key, create) {
        if (!this.boundariesData[key] && create) {
            this.boundariesData[key] = { 'state':'created' };
        }
        return this.boundariesData[key];
    }

    setBoundaryData(key, boundaryData) {
        // boundaryData = { name:'string', lat:[3+], lon:[3+], [disabled:true] }
        this.boundariesData[key] = boundaryData;
        boundaryData.timestamp = Math.ceil(new Date().getTime() / 1000);
        this.saveBoundaries();
    }

    deleteBoundaryData(key) {
        this.callbacks.removingBoundary.fire(key);
        const utcnow = Math.ceil(new Date().getTime() / 1000);
        this.boundariesData[key] = { state:'deleted', timestamp:utcnow };
        this.saveBoundaries();
    }
}

export class BoundaryEditor {
    constructor(map1) {
        this.map1 = map1;
        this.boundaries = {};
        this.boundaryMarkerIcon = makeMarkerIcon(makeCircleImageDataUrl('white', 6), undefined, 2 * 6);
        this.boundaryMidpointMarkerIcon = makeMarkerIcon(makeCircleImageDataUrl('white', 4), undefined, 2 * 4);
        this.map1BoundaryDisplayLayerGroup = pwMap.layerGroup();
        this.map1BoundaryEditingLayerGroup = pwMap.layerGroup();
        this.boundaryDialog = null;
        this.boundaryDialogKey = null;
        this.activeBoundary = null; // key of boundary being created point-by-point
        this.datasource = new BoundaryDatasource();
        this.datasource.callbacks.addedBoundary.add(this.addedBoundaryData.bind(this));
        this.datasource.callbacks.removingBoundary.add(this.removingBoundaryData.bind(this));

        this._boundaryEditorClickHandler = this.boundaryEditorClickHandler.bind(this);
        this._boundaryEditorMoveHandler = this.boundaryEditorMoveHandler.bind(this);
    }

    initialise() {
        const that = this;
        $j('pw-boundary-buttons').on('editing-toggle', function() {
            (this.editing = !this.editing) ? that.enable() : that.disable();
        });
        this.updateVisibility();
        this.datasource.initialise();
        if (isOffshoreApp) {
            window.app.addSettingsListener(function(){ return that.boundariesSettingsListener.apply(that, arguments); });
        }
        window.app?.addSettingsListener(() => {
            try {
                this.updateBoatMarker();
            } catch (e) {

            }
        });
    }

    getBoundary(key) {
        return this.boundaries[key];
    }

    setBoundaryName(key, name) {
        const boundary = this.getBoundary(key);
        if (boundary) {
            boundary.name = name;
            this.saveBoundary(key);
        }
    }

    setBoundaryEnabled(key, enabled) {
        const boundary = this.getBoundary(key);
        if (boundary) {
            boundary.enabled = !!enabled;
            this.saveBoundary(key);
        }
    }

    deleteBoundary(key) {
        this.datasource.deleteBoundaryData(key);
    }

    setBoundaryPointFromText(key, index, text) {
        if (isValidCoord(text)) {
            const coords = pwMap.utils.parseCoords(text);
            const boundary = this.getBoundary(key);
            boundary.points[index].lat = coords.lat;
            boundary.points[index].lon = coords.lon;
            this.saveBoundary(key);
            if (this.boundaryDialog) {
                pwMap.Element.setPosition(this.boundaryDialog, boundary.markers[index]);
            }
        }
    }

    deleteBoundaryPoint(key, index) {
        const boundary = this.getBoundary(key);
        if (boundary.points.length > 3) {
            this.removeBoundaryPoint(key, index);
            this.removeBoundaryMarker(key, index);
            this.removeBoundaryMidpointMarker(key, index);
            this.saveBoundary(key);
            return true;
        }
        return false;
    }

    addedBoundaryData(key, newBoundaryData) {
        this.createBoundary(key, newBoundaryData);
        for (let i = 0; i < newBoundaryData.lat.length; i++) {
            this.insertBoundaryPoint(key, i, {lat:newBoundaryData.lat[i], lon:newBoundaryData.lon[i]});
            this.insertBoundaryMarker(key, i);
        }
        this.closeBoundary(key);
        this.redrawBoundary(key);
    }

    removingBoundaryData(key) {
        if (this.boundaryDialogKey === key) {
            this.hideBoundaryDialog();
        }
        this.removeBoundary(key);
    }

    boundariesSettingsListener(updatedSettings) {
        if ('App_CurrentPage' in updatedSettings) {
            this.updateVisibility();
        }
    }

    redrawBoundary(key) {
        const boundary = this.getBoundary(key);
        const path = boundary.points;
        const markers = boundary.markers;
        const midMarkers = boundary.midpointMarkers;
        const polygon = boundary.polygon;
        let polyline = boundary.polyline;
        if (!polyline) {
            polyline = boundary.polyline = this.createBoundaryPolyline(boundary.closed);
            pwMap.LayerGroup.addItem(this.map1BoundaryDisplayLayerGroup, polyline);
        } else {
            polyline.closed = boundary.closed;
        }
        const dividedPath = [];
        for (let i = 0; i < path.length; i++) {
            pwMap.Marker.setPosition(markers[i], path[i]);
            dividedPath.push(path[i]);
            if (polyline.closed) {
                const midPoint = pwMap.utils.midpoint(path[i], path[(i + 1) % path.length]);
                pwMap.Marker.setPosition(midMarkers[i], midPoint);
                dividedPath.push(midPoint);
            }
        }
        if (polygon) {
            pwMap.Polygon.setPath(polygon, dividedPath);
            pwMap.polygon(polygon, {fillOpacity: boundary.enabled ? 0.2 : 0.01});
        } else {
            if (boundary.polylineEnd) {
                dividedPath.push(boundary.polylineEnd);
            }
            pwMap.Polyline.setPath(polyline, dividedPath);
        }
    }

    saveBoundary(key) {
        const boundary = this.getBoundary(key);
        if (boundary.closed) {
            const boundaryData = this.datasource.getBoundaryData(key, true);
            boundaryData.name = boundary.name;
            if (!boundary.enabled) {
                boundaryData.disabled = true;
            } else {
                delete boundaryData.disabled;
            }
            boundaryData.lat = [];
            boundaryData.lon = [];
            const path = boundary.points;
            for (let i = 0; i < path.length; i++) {
                boundaryData.lat[i] = path[i].lat;
                boundaryData.lon[i] = path[i].lon;
            }
            this.datasource.setBoundaryData(key, boundaryData);
        }
        this.redrawBoundary(key);
    }

    promoteBoundaryMarker(key, marker) {
        const boundary = this.getBoundary(key);
        const i = boundary.midpointMarkers.indexOf(marker);
        if (i !== -1) {
            this.removeBoundaryMidpointMarker(key, i);
            this.insertBoundaryPoint(key, i + 1, marker.persistentPoint);
            this.insertBoundaryMarker(key, i + 1, marker);
            this.insertBoundaryMidpointMarker(key, i);
            this.insertBoundaryMidpointMarker(key, i + 1);
            return true;
        }
        return false;
    }

    closeBoundary(key) {
        const boundary = this.getBoundary(key);
        const polyline = boundary.polyline;
        if (polyline) {
            pwMap.LayerGroup.removeItem(this.map1BoundaryDisplayLayerGroup, polyline);
            boundary.polyline = null;
        }
        for (let i = 0; i < boundary.points.length; i++) {
            this.insertBoundaryMidpointMarker(key, i);
        }
        boundary.polygon = this.createBoundaryPolygon(key);
        boundary.closed = true;
        pwMap.LayerGroup.addItem(this.map1BoundaryDisplayLayerGroup, boundary.polygon);
    }

    boundaryMarkerClick(key, marker, p) {
        if (this.hideBoundaryDialog()) {
            return;
        }
        const boundary = this.getBoundary(key);
        if (this.activeBoundary === key && marker === boundary.markers[0] && boundary.markers.length >= 3) {
            this.activeBoundary = null;
            this.closeBoundary(key);
            this.saveBoundary(key);
            this.map1._atlasMap.doubleClickable_invalidate();
            return;
        } else {
            marker.persistentPoint.lat = marker.lat;
            marker.persistentPoint.lon = marker.lon;
            if (this.promoteBoundaryMarker(key, marker)) {
                this.saveBoundary(key);
            } else {
                const index = boundary.markers.indexOf(marker);
                this.showBoundaryPointDialog(key, index);
            }
        }
    }

    boundaryMarkerDrag(key, marker, p) {
        this.hideBoundaryDialog();
        marker.persistentPoint.lat = marker.lat;
        marker.persistentPoint.lon = marker.lon;
        this.promoteBoundaryMarker(key, marker);
        this.saveBoundary(key);
    }

    createBoundaryMarker(key, persistentPoint, isMidPoint) {
        const that = this;
        const baseMarkerOptions = {
            icon: isMidPoint ? this.boundaryMidpointMarkerIcon : this.boundaryMarkerIcon,
            clickable: true,
            draggable: true,
            title: '',
            raiseOnDrag: false,
            zIndex: pwMap.zLevels.routeWaypoint + (isMidPoint ? -1 : 0),
            click: function (p) {
                const marker = this;
                // getting double events, quick workaround
                if (!that.boundaryMarkerClickDebounceHandle) {
                    that.boundaryMarkerClickDebounceHandle = setTimeout(function () {
                        that.boundaryMarkerClickDebounceHandle = null;
                    }, 500);
                    that.boundaryMarkerClick(key, marker, p);
                }
            },
            drag: function (p) {
                that.boundaryMarkerDrag(key, this, p);
            }
        };
        const markerOptions = {...baseMarkerOptions};
        markerOptions.position = persistentPoint;
        const marker = pwMap.marker(markerOptions);
        marker.persistentPoint = persistentPoint;
        marker.cursor = 'default';
        return marker;
    }

    createBoundaryPolyline(closed) {
        const polylineOptions = {
            path: [],
            closed: closed,
            lineColour: 'black',
            lineWeight: 2,
            zIndex: pwMap.zLevels.routeLine,
            subdivide: true
        };
        return pwMap.polyline(polylineOptions);
    }

    createBoundaryPolygon(key) {
        const that = this;
        const polygonOptions = {
            path: [],
            lineColour: 'black',
            lineWeight: 2,
            fillColour: 'red',
            fillOpacity: 0.2,
            zIndex: pwMap.zLevels.routeLine,
            subdivide: true,
            click: function (p) {
                that.boundaryClicked(key, p);
            }
        };
        const polygon = pwMap.polygon(polygonOptions);
        polygon.cursor = 'default';
        return polygon;
    }

    createBoundaryDialog(key, p) {
        const that = this;
        const boundary = this.getBoundary(key);
        const options = {
            getText: function () {
                return boundary.name;
            },
            setText: function (text) {
                that.setBoundaryName(key, text);
            },
            getDeleteText: function () {
                return 'Delete Boundary';
            },
            deleteObject: function () {
                that.deleteBoundary(key);
            },
            innerJElement: $j('<div>').append(
                $j('<input type="checkbox" id="routing-boundary-enabled-checkbox">')
                    .on('change', function () {
                        that.setBoundaryEnabled(key, !!$j(this).is(':checked'));
                    })
                    .prop("checked", !!that.getBoundary(key).enabled),
                $j('<label for="routing-boundary-enabled-checkbox" class="routing-boundary-enabled-label">')
                    .text(gettext('Boundary Enabled'))
            )
        };
        return this.createBoundaryEditDialog(p, options);
    }

    createBoundaryPointDialog(key, index) {
        const that = this;
        const point = this.getBoundary(key).points[index];
        const options = {
            getText: function () {
                return pwMap.utils.formatCoords(point);
            },
            setText: function (text) {
                that.setBoundaryPointFromText(key, index, text);
            },
            getDeleteText: function () {
                return 'Delete Point';
            },
            deleteObject: function () {
                that.deleteBoundaryPoint(key, index);
            },
            canDelete: function () {
                return that.getBoundary(key).points.length > 3;
            }
        };
        return this.createBoundaryEditDialog(point, options);
    }

    createBoundaryEditDialog(p, options) {
        const that = this;
        const jElem = $j('<div class="routing-boundary-dialog">').append(
            $j('<div class="routing-boundary-dialog-arrow">'),
            $j('<input class="routing-boundary-dialog-input">')
                .val(options.getText())
                .on('keyup', function (e) {
                    if (e.keyCode === 13) {
                        $j(this).trigger('blur');
                    }
                    if (e.keyCode === 27) {
                        $j(this).val(options.getText());
                        $j(this).trigger('blur');
                    }
                })
                .on('change', function () {
                    const text = $j(this).val().trim();
                    if (text) {
                        options.setText(text);
                    }
                    $j(this).val(options.getText());
                })
        );
        if (options.innerJElement) {
            jElem.append(options.innerJElement);
        }
        if (options.deleteObject) {
            const deleteText = options.getDeleteText ? options.getDeleteText() : null;
            const jDeleteButton = $j('<div class="routing-boundary-delete-button">').text(gettext(deleteText || 'Delete'));
            if (!options.canDelete || options.canDelete()) {
                jDeleteButton
                    .addClass('routing-boundary-delete-button-enabled')
                    .on('click', function () {
                        showDeleteConfirmation(true);
                    });
            }
            jElem.append(
                jDeleteButton,
                $j('<div class="routing-boundary-delete-confirm">').append(
                    $j('<div class="routing-boundary-delete-confirm-text">').text(gettext('Are you sure?')),
                    $j('<div class="routing-boundary-delete-confirm-button">')
                        .text(gettext('No'))
                        .on('click', function () {
                            showDeleteConfirmation(false);
                        }),
                    $j('<div class="routing-boundary-delete-confirm-button">')
                        .text(gettext('Yes'))
                        .on('click', function () {
                            showDeleteConfirmation(false);
                            options.deleteObject();
                            that.hideBoundaryDialog();
                        })
                )
            );
        }

        function showDeleteConfirmation(show) {
            $j('.routing-boundary-delete-confirm').toggle(show);
            $j('.routing-boundary-delete-button').toggle(!show);
        }

        if (window.GLOBAL_isSmartPhone) {
            this.map1.setCentre(p);
        }
        return pwMap.element({lat: p.lat, lon: p.lon, elem: jElem[0], centred: true, centredY: false, centredX: true});
    }

    hideBoundaryDialog() {
        if (this.boundaryDialog) {
            pwMap.LayerGroup.removeItem(this.map1BoundaryEditingLayerGroup, this.boundaryDialog);
            this.boundaryDialog = null;
            this.boundaryDialogKey = null;
            return true;
        }
        return false;
    }

    showBoundaryDialog(key, p) {
        if (!this.boundaryDialog) {
            this.boundaryDialog = this.createBoundaryDialog(key, p);
            this.boundaryDialogKey = key;
            pwMap.LayerGroup.addItem(this.map1BoundaryEditingLayerGroup, this.boundaryDialog);
        }
    }

    showBoundaryPointDialog(key, index) {
        if (!this.boundaryDialog) {
            this.boundaryDialog = this.createBoundaryPointDialog(key, index);
            this.boundaryDialogKey = key;
            pwMap.LayerGroup.addItem(this.map1BoundaryEditingLayerGroup, this.boundaryDialog);
        }
    }

    boundaryClicked(key, p) {
        if (!this.enabled) return;
        if (!this.hideBoundaryDialog()) {
            this.showBoundaryDialog(key, p);
        }
    }

    insertBoundaryPoint(key, i, p) {
        const boundary = this.getBoundary(key);
        boundary.points.splice(i, 0, p);
    }

    removeBoundaryPoint(key, i, p) {
        const boundary = this.getBoundary(key);
        boundary.points.splice(i, 1);
    }

    insertBoundaryMarker(key, i, marker) {
        const boundary = this.getBoundary(key);
        const p = boundary.points[i];
        if (!marker) {
            marker = this.createBoundaryMarker(key, p, false, marker);
        } else {
            marker.icon = this.boundaryMarkerIcon;
            marker.zIndex = pwMap.zLevels.routeWaypoint;
        }
        pwMap.LayerGroup.addItem(this.map1BoundaryEditingLayerGroup, marker);
        boundary.markers.splice(i, 0, marker);
    }

    insertBoundaryMidpointMarker(key, i) {
        const boundary = this.getBoundary(key);
        const midPoint = pwMap.utils.midpoint(boundary.points[i], boundary.points[(i + 1) % boundary.points.length]);
        const midMarker = this.createBoundaryMarker(key, midPoint, true);
        boundary.midpointMarkers.splice(i, 0, midMarker);
        pwMap.LayerGroup.addItem(this.map1BoundaryEditingLayerGroup, midMarker);
    }

    removeBoundaryMarker(key, i) {
        const boundary = this.getBoundary(key);
        pwMap.LayerGroup.removeItem(this.map1BoundaryEditingLayerGroup, boundary.markers[i]);
        boundary.markers.splice(i, 1);
    }

    removeBoundaryMidpointMarker(key, i) {
        const boundary = this.getBoundary(key);
        pwMap.LayerGroup.removeItem(this.map1BoundaryEditingLayerGroup, boundary.midpointMarkers[i]);
        boundary.midpointMarkers.splice(i, 1);
    }

    createBoundary(key, boundaryData) {
        const boundary = this.getBoundary(key) || {};
        this.boundaries[key] = Object.assign(boundary, {
            name: boundaryData ? boundaryData.name : pgettext('default user defined route boundary name', 'Boundary'),
            enabled: boundaryData ? !boundaryData.disabled : true,
            closed: !!boundaryData,
            points: [],
            markers: [],
            midpointMarkers: [],
            polyline: null,
            polylineEnd: null,
            polygon: null
        });
    }

    removeBoundary(key) {
        const boundary = this.getBoundary(key);
        if (!boundary) {
            return;
        }
        const markers = boundary.markers;
        for (let i = 0; i < markers.length; i++) {
            pwMap.LayerGroup.removeItem(this.map1BoundaryEditingLayerGroup, markers[i]);
        }
        const midMarkers = boundary.midpointMarkers;
        for (let i = 0; i < midMarkers.length; i++) {
            pwMap.LayerGroup.removeItem(this.map1BoundaryEditingLayerGroup, midMarkers[i]);
        }
        const polyline = boundary.polyline;
        if (polyline) {
            pwMap.LayerGroup.removeItem(this.map1BoundaryDisplayLayerGroup, polyline);
        }
        const polygon = boundary.polygon;
        if (polygon) {
            pwMap.LayerGroup.removeItem(this.map1BoundaryDisplayLayerGroup, polygon);
        }
        delete this.boundaries[key];
    }

    appendActiveBoundaryPoint(p) {
        let key = this.activeBoundary;
        if (!key) {
            key = Math.floor(Math.random() * 1000000 + 5000000).toString(16) + Math.floor(new Date().getTime()).toString(16);
            key = key.substring(Math.max(0, key.length - 16));
            this.activeBoundary = key;
            this.createBoundary(key);
            this.map1._atlasMap.doubleClickable = false;
        }
        const boundary = this.getBoundary(key);
        const i = boundary.points.length;
        this.insertBoundaryPoint(key, i, p);
        this.insertBoundaryMarker(key, i);
        this.redrawBoundary(key);
    }

    boundaryEditorClickHandler(p) {
        if (!this.enabled) return;
        if (!this.hideBoundaryDialog()) {
            this.appendActiveBoundaryPoint({lat: p.lat, lon: p.lon});
        }
    }

    boundaryEditorMoveHandler(p) {
        if (!this.enabled) return;
        this.pointerMovedActiveBoundary(p);
    }

    pointerMovedActiveBoundary(p) {
        const key = this.activeBoundary;
        if (key && !window.GLOBAL_isSmartPhone) {
            const boundary = this.getBoundary(key);
            boundary.polylineEnd = p;
            this.redrawBoundary(key);
        }
    }

    updateVisibility() {
        let showEditor = true;
        if (isOffshoreApp) {
            const currentPage = app.getSetting('App_CurrentPage');
            showEditor = currentPage in {WeatherRouting:1, TripPlanning:1};
        }
        if (showEditor) {
            this.show();
        } else {
            this.hide();
        }
    }

    enable() {
        if (!this.enabled) {
            this.enabled = true;
            this.map1._atlasMap.addListener('click', this._boundaryEditorClickHandler);
            this.map1._atlasMap.addListener('move', this._boundaryEditorMoveHandler);
            this.map1._atlasMap.cursor = 'crosshair';
            this.wasTooltipsEnabled = this.map1._atlasMap.tooltipsEnabled;
            this.map1._atlasMap.tooltipsEnabled = false;
            this.map1.addItem(this.map1BoundaryEditingLayerGroup);
            $j('#boundary-button-editor').addClass('boundary-button-active');
            $j('#boundary-button-pointer').removeClass('boundary-button-active');
        }
    }

    disable() {
        if (this.enabled) {
            this.enabled = false;
            this.map1._atlasMap.removeListener('click', this._boundaryEditorClickHandler);
            this.map1._atlasMap.removeListener('move', this._boundaryEditorMoveHandler);
            this.map1._atlasMap.cursor = 'auto';
            this.map1._atlasMap.tooltipsEnabled = this.wasTooltipsEnabled;
            this.map1.removeItem(this.map1BoundaryEditingLayerGroup);
            this.hideBoundaryDialog();
            if (this.activeBoundary) {
                this.removeBoundary(this.activeBoundary);
                this.activeBoundary = null;
            }
            $j('#boundary-button-pointer').addClass('boundary-button-active');
            $j('#boundary-button-editor').removeClass('boundary-button-active');
        }
    }

    show() {
        if (!this.visible) {
            this.visible = true;
            this.map1.addItem(this.map1BoundaryDisplayLayerGroup);
            $j('pw-boundary-buttons').removeClass('hidden');
        }
    }

    hide() {
        if (this.visible) {
            this.visible = false;
            this.disable();
            this.map1.removeItem(this.map1BoundaryDisplayLayerGroup);
            $j('pw-boundary-buttons').addClass('hidden');
        }
    }
}

