import * as pwMap from './map.js';
import { makeCircleImageDataUrl, makeMarkerIcon } from "./components/routing-boundary-editor.js";
import { isSmartPhone, isSmartPhoneBrowser } from "../platform.js";

function makeMobileHoverImageUrl(outerRadius, innerRadius, innerColour, outerColour, innerOpacity, outerOpacity) {
    try {
        const ratio = window.devicePixelRatio || 1;
        const canvas = document.createElement("canvas");
        canvas.width = outerRadius * 2 * ratio;
        canvas.height = outerRadius * 2 * ratio;
        const context = canvas.getContext("2d");
        context.scale(ratio, ratio);
        context.beginPath();
        context.arc(outerRadius, outerRadius, innerRadius - 1, 0, 2 * Math.PI, false);
        context.fillStyle = innerColour;
        context.fill();
        // Use thinner outlines on retina
        context.lineWidth = 2 / ratio;
        context.strokeStyle = "black";
        context.globalAlpha = innerOpacity ? innerOpacity : 1;
        context.stroke();
        context.beginPath();
        context.arc(outerRadius, outerRadius, outerRadius - 1, 0, 2 * Math.PI, false);
        context.fillStyle = outerColour;
        context.fill();
        // Use thinner outlines on retina
        context.lineWidth = 2 / ratio;
        context.strokeStyle = "black";
        context.globalAlpha = outerOpacity ? outerOpacity : 1;
        context.stroke();
        return canvas.toDataURL("image/png");
    } catch (ex) {
        console.log(ex);
        //return STATIC_URL + 'images/gmap_marker_route7.png'; // white
    }
}

export default class MapRuler {
    constructor(map) {
        const that = this;
        // Define different contexts for jQuery to use in the event there are
        // nested ShadowDOM elements such as in local knowledge
        this.mapContext = document;
        this.pageContext = document;
        this.map = map;
        this.rulerPointMarkerIcon = makeMarkerIcon(makeCircleImageDataUrl('white', 6), undefined, 2 * 6);
        this.rulerHoverMarkerIcon = makeMarkerIcon(makeCircleImageDataUrl('transparent', 6, 0.3), undefined, 2 * 6);
        this.mobileHoverMarkerIcon = makeMarkerIcon(makeMobileHoverImageUrl(9, 3, 'black', 'transparent', 1, 0.4), undefined, 2 * 9);
        this.mapRulerLayerGroup = pwMap.layerGroup();
        this.rulerPoints = [];
        this.rulerPointKeys = [];
        this.polyline = null;
        this.polylineOptions = {
            path: [],
            closed: false,
            clickable: isSmartPhone || isSmartPhoneBrowser ? false : true,
            lineColour: 'black',
            lineWeight: 2,
            lineStyle: 'solid',
            zIndex: pwMap.zLevels.routeLine,
            subdivide: true,
            cursor: 'auto',
            click: function (p, targetInfo) {
                that.clickPolyline(p, this, targetInfo);
            }
        };
        this.hoverPoint = null;
        this.hoverLine = null;
        this.hoverText = null;
        this.activeHoverPoint = null;
        this.rulerPaused = false;
        this.createPoint = this.createPoint.bind(this);
        this.rulerHoverHandler = this.rulerHoverHandler.bind(this);
        this.polylineDividedHoverHandler = this.polylineDividedHoverHandler.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
    }

    createPoint(p, midpoint, index) {
        if (this.rulerPaused) {
            this.map._atlasMap.addListener('move', this.rulerHoverHandler);
            this.map._atlasMap.addListener('move', this.polylineDividedHoverHandler);
            this.rulerPaused = false;
            $j('pw-map-ruler-button', this.pageContext).prop({ 'showEndPrompt': true })
        }
        const length = this.rulerPoints.length
        //prevent creating a line with no length - subsequently prevents rapidly adding multiple markers to the same point on the map
        if (length) {
            const mostRecentPoint = { lat: this.rulerPoints[length - 1].lat, lon: this.rulerPoints[length - 1].lon };
            if (p.lat === mostRecentPoint.lat && p.lon === mostRecentPoint.lon) {
                return;
            }
        }
        const that = this;
        let key = Math.floor(Math.random() * 1000000 + 5000000).toString(16) + Math.floor(new Date().getTime()).toString(16);
        key = key.substring(Math.max(0, key.length - 16));
        const markerOptions = {
            icon: this.rulerPointMarkerIcon,
            clickable: isSmartPhone || isSmartPhoneBrowser ? false : true,
            doubleClickable: false,
            draggable: false,
            title: '',
            raiseOnDrag: false,
            zIndex: pwMap.zLevels.routePath,
            position: { lat: p.lat, lon: p.lon },
            click: isSmartPhone || isSmartPhoneBrowser ? null : function (p) {
                // FIXME: Getting double events similar to routing-boundary-editor.js
                if (!that.rulerMarkerClickDebounceHandle) {
                    that.rulerMarkerClickDebounceHandle = setTimeout(function () {
                        that.rulerMarkerClickDebounceHandle = null;
                    }, 500);
                    if (that.activeHoverPoint === key) {
                        that.disableHover();
                        that.map._atlasMap.addListener('move', that.rulerHoverHandler);
                        this.draggable = true;
                    } else {
                        that.removePoint(key);
                    }
                }
            },
            drag: isSmartPhone || isSmartPhoneBrowser ? null : function () {
                that.dragPoint(key);
            }
        };
        const marker = pwMap.marker(markerOptions);
        // If a polyline is clicked, midpoint will be set to true, so we shouldn't append the new point
        if (!midpoint) {
            this.rulerPointKeys.push(key);
            this.rulerPoints.push(marker);
        } else {
            this.rulerPointKeys.splice(index + 1, 0, key);
            this.rulerPoints.splice(index + 1, 0, marker);
        }
        pwMap.LayerGroup.addItem(this.mapRulerLayerGroup, marker);
        if (!midpoint) {
            if (!this.polyline) {
                this.createPolyline(p);
            } else {
                this.extendPolyline(p);
            }
        }
        $j('pw-map-ruler-button', this.pageContext).prop('tempDistance', 0);
        this.updateTotalDistance();
        this.manualHoverUpdate();
        $j('.hoverText', this.mapContext).hide(); // Hide the hover text so we dont see "0.00nm" on top of every newly created point
    }

    removePoint(key) {
        $j('.hoverText', this.mapContext).show(); // In case a point is created and deleted without moving the mouse
        const index = this.rulerPointKeys.indexOf(key);
        if (index === 0 && this.rulerPoints.length === 1) {
            if (this.rulerPointKeys.length > 1) {
                $j('.' + key + this.rulerPointKeys[1], this.mapContext).remove();
            } else {
                $j('.hoverText', this.mapContext).remove();
                pwMap.LayerGroup.removeItem(this.mapRulerLayerGroup, this.hoverLine);
                this.hoverLine = null;
            }
        } else if (index === this.rulerPointKeys.length - 1 && this.rulerPointKeys.length > 1) {
            $j('.' + this.rulerPointKeys[this.rulerPointKeys.length - 2] + key, this.mapContext).remove();
        } else if (this.rulerPointKeys.length) {
            $j('.' + key + this.rulerPointKeys[index + 1], this.mapContext).remove();
            $j('.' + this.rulerPointKeys[index - 1] + key, this.mapContext).remove();
        } else {
            $j('.hoverText', this.mapContext).remove();
        }
        if (index === -1 || index === undefined) return;
        const marker = this.rulerPoints[index];
        pwMap.LayerGroup.removeItem(this.mapRulerLayerGroup, marker);
        this.rulerPointKeys.splice(index, 1);
        this.rulerPoints.splice(index, 1);
        this.contractPolyline(index);
        this.manualHoverUpdate();
        this.updateHoverDistance();
        this.updateTotalDistance();
    }

    removeLastPoint() {
        this.removePoint(this.rulerPointKeys[this.rulerPointKeys.length - 1]);
    }

    dragPoint(key) {
        const index = this.rulerPointKeys.indexOf(key);
        this.updatePolyline(index);
        this.updateTotalDistance();
        this.updateDistanceText(key, index);
    }

    updatePolyline(index,) {
        this.polyline.path[index] = this.rulerPoints[index];
        pwMap.Polyline.setPath(this.polyline, this.polyline.path);
    }

    clickPolyline(p, polyline, targetInfo) {
        // Use targetInfo to find which segment of the polyline was clicked
        // this value is based on the subdivided path (due to polylines being curved),
        // so we need to generate the regular path index from the subdivided index
        let subdividedIndex = Math.floor(targetInfo);
        let index = this.getPolylinePathIndex(subdividedIndex);
        if (index < 0) return;
        this.dividePolyline(p, index);
        this.disableHover();
        this.activeHoverPoint = this.rulerPointKeys[index + 1];
        this.rulerPoints[index + 1].draggable = false;
        this.map._atlasMap.addListener('move', this.polylineDividedHoverHandler);
    }

    getPolylinePathIndex(subdividedIndex) {
        for (let i = subdividedIndex; i >= 0; i--) {
            for (let j = 0; j < this.polyline.relativePath.length; j++) {
                if (this.polyline.relativePath[j].lat.toFixed(4) === this.polyline.subdividedPath[i].lat.toFixed(4)
                    && this.polyline.relativePath[j].lon.toFixed(4) === this.polyline.subdividedPath[i].lon.toFixed(4)) {
                    return j
                }
            }
        }
        return -1;
    }

    createPolyline(p) {
        //If this method is called when the first point is added, a polyline should not be created
        if (this.rulerPoints.length <= 1) return;
        this.polyline = pwMap.polyline(this.polylineOptions);
        let path = [{
            lat: this.rulerPoints[this.rulerPoints.length - 2].lat,
            lon: this.rulerPoints[this.rulerPoints.length - 2].lon
        },
            p];
        pwMap.LayerGroup.addItem(this.mapRulerLayerGroup, this.polyline);
        pwMap.Polyline.setPath(this.polyline, path);
        this.addDistanceText(this.polyline.path, this.rulerPointKeys[this.rulerPoints.length - 2], this.rulerPointKeys[this.rulerPoints.length - 1]);
    }

    extendPolyline(p) {
        const pathLength = this.polyline.path.push(p)
        pwMap.Polyline.setPath(this.polyline, this.polyline.path);
        this.addDistanceText([this.polyline.path[pathLength - 2], this.polyline.path[pathLength - 1]],
            this.rulerPointKeys[this.rulerPoints.length - 2],
            this.rulerPointKeys[this.rulerPoints.length - 1]
        );
    }

    dividePolyline(p, index) {
        $j('.' + this.rulerPointKeys[index] + this.rulerPointKeys[index + 1], this.mapContext).remove();
        this.polyline.path.splice(index + 1, 0, p);
        pwMap.Polyline.setPath(this.polyline, this.polyline.path);
        this.createPoint(p, true, index);
        this.addDistanceText([this.polyline.path[index], this.polyline.path[index + 1]], this.rulerPointKeys[index], this.rulerPointKeys[index + 1]);
        this.addDistanceText([this.polyline.path[index + 1], this.polyline.path[index + 2]], this.rulerPointKeys[index + 1], this.rulerPointKeys[index + 2]);
    }

    contractPolyline(index) {
        if (!this.polyline) return;
        if (index !== 0 && index !== this.polyline.path.length - 1) {
            let newPath = [this.polyline.path[index - 1], this.polyline.path[index + 1]];
            this.addDistanceText(newPath, this.rulerPointKeys[index - 1], this.rulerPointKeys[index]);
        } else if (this.polyline.path.length == 1) {
            this.polyline = null;
            return;
        }
        this.polyline.path.splice(index, 1);
        pwMap.Polyline.setPath(this.polyline, this.polyline.path);
    }

    manualHoverUpdate() {
        if (!this.hoverLine) return;
        const
            length = this.rulerPoints.length,
            p = { lat: this.rulerPoints[length - 1].lat, lon: this.rulerPoints[length - 1].lon }
        pwMap.Polyline.setPath(this.hoverLine, [p, this.hoverLine.path[1]]);
        this.updateDistanceTextPosition(this.hoverLine.path, 'hover', 'Text');
    }

    addDistanceText(path, id1, id2) {
        let distance = pwMap.utils.haversineDistance(path[0], path[1]);
        let midPoint = pwMap.utils.midpoint(path[0], path[1]);
        let blackText = document.createElement('DIV');
        let whiteText = document.createElement('DIV');
        blackText.innerText = whiteText.innerText = `${distance.toFixed(2)}nm`;
        blackText.style.fontSize = whiteText.style.fontSize = '11px';
        blackText.style.webkitTextStroke = isSmartPhone || isSmartPhoneBrowser ? '1px black' : '0px black';
        whiteText.style.webkitTextStrokeWidth = '2px';
        whiteText.style.webkitTextStrokeColor = 'white';
        blackText.classList.add(id1 + id2);
        whiteText.classList.add(id1 + id2);
        const mapBlackText = pwMap.element({ lat: midPoint.lat, lon: midPoint.lon, elem: blackText });
        const mapWhiteText = pwMap.element({ lat: midPoint.lat, lon: midPoint.lon, elem: whiteText });
        mapBlackText.pointerEvents = mapWhiteText.pointerEvents = false;
        pwMap.LayerGroup.addItem(this.mapRulerLayerGroup, mapWhiteText);
        pwMap.LayerGroup.addItem(this.mapRulerLayerGroup, mapBlackText);
        if (id1 === 'hover') {
            this.hoverText = [mapBlackText, mapWhiteText];
        }
    }

    removeDistanceText(key, index) {
        if (index === this.rulerPoints.length - 1) {
            $j('.' + this.rulerPointKeys[this.rulerPointKeys.length - 2] + key, this.mapContext).remove();
        } else {
            $j('.' + key + this.rulerPointKeys[index + 1], this.mapContext).remove();
            if (index !== 0) {
                $j('.' + this.rulerPointKeys[index - 1] + key, this.mapContext).remove();
            }
        }
    }

    updateDistanceText(key, index) {
        let newDistance;
        if (index === this.rulerPoints.length - 1) {
            newDistance = pwMap.utils.haversineDistance(this.rulerPoints[index - 1], this.rulerPoints[index]).toFixed(2);
            $j('.' + this.rulerPointKeys[index - 1] + key, this.mapContext).text(newDistance + 'nm');
            this.updateDistanceTextPosition([this.polyline.path[index - 1], this.polyline.path[index]], this.rulerPointKeys[index - 1], key);
        } else {
            newDistance = pwMap.utils.haversineDistance(this.rulerPoints[index], this.rulerPoints[index + 1]).toFixed(2);
            $j('.' + this.rulerPointKeys[index] + this.rulerPointKeys[index + 1], this.mapContext).text(newDistance + 'nm');
            this.updateDistanceTextPosition([this.polyline.path[index], this.polyline.path[index + 1]], key, this.rulerPointKeys[index + 1]);
            if (index !== 0) {
                newDistance = pwMap.utils.haversineDistance(this.rulerPoints[index - 1], this.rulerPoints[index]).toFixed(2);
                $j('.' + this.rulerPointKeys[index - 1] + this.rulerPointKeys[index], this.mapContext).text(newDistance + 'nm');
                this.updateDistanceTextPosition([this.polyline.path[index - 1], this.polyline.path[index]], this.rulerPointKeys[index - 1], key);
            }
        }
    }

    updateDistanceTextPosition(path, id1, id2) {
        let toUpdate = [];
        for (const element of this.mapRulerLayerGroup._items) {
            if (element.element && element.element.className === id1 + id2) {
                toUpdate.push(element);
            }
        }
        const newMidPoint = pwMap.utils.midpoint(path[0], path[1]);
        toUpdate.map(element => {
            element.lat = newMidPoint.lat;
            element.lon = newMidPoint.lon;
        });
        if (id1 === "hover") {
            $j('.hoverText', this.mapContext).text(pwMap.utils.haversineDistance(path[0], path[1]).toFixed(2) + 'nm');
        }
    }

    updateTotalDistance() {
        if (!this.polyline) return;
        let totalDistance = 0;
        for (var i = 0; i < this.polyline.path.length - 1; i++) {
            totalDistance += pwMap.utils.haversineDistance(this.polyline.path[i], this.polyline.path[i + 1]);
        }
        $j('pw-map-ruler-button', this.pageContext).prop('distance', totalDistance);
    }

    updateHoverDistance() {
        if (this.hoverLine) {
            $j('pw-map-ruler-button', this.pageContext).prop('tempDistance', pwMap.utils.haversineDistance(this.hoverLine.path[0], this.hoverLine.path[1]));
        } else {
            $j('pw-map-ruler-button', this.pageContext).prop('tempDistance', 0);
        }
    }

    createHoverpoint(p) {
        if (!this.hoverPoint) {
            let hoverMarkerOptions = {
                icon: isSmartPhone || isSmartPhoneBrowser ? this.mobileHoverMarkerIcon : this.rulerHoverMarkerIcon,
                lineOpacity: '0.3',
                clickable: false,
                title: '',
                zIndex: pwMap.zLevels.routeLine,
                position: { lat: p.lat, lon: p.lon },
            };
            this.hoverPoint = pwMap.marker(hoverMarkerOptions);
            pwMap.LayerGroup.addItem(this.mapRulerLayerGroup, this.hoverPoint);
        }
    }

    createHoverline(p) {
        if (!this.rulerPoints.length) return;
        let hoverPolylineOptions = { ...this.polylineOptions };
        hoverPolylineOptions.lineOpacity = isSmartPhone || isSmartPhoneBrowser ? '1' : '0.3';
        hoverPolylineOptions.zIndex = pwMap.zLevels.routeLine - 1;
        hoverPolylineOptions.clickable = false;
        if (isSmartPhone || isSmartPhoneBrowser) {
            hoverPolylineOptions.lineColour = 0xFFFFFF;
            hoverPolylineOptions.lineStyle = 'dotted';
        }
        this.hoverLine = pwMap.polyline(hoverPolylineOptions);
        pwMap.LayerGroup.addItem(this.mapRulerLayerGroup, this.hoverLine);
        pwMap.Polyline.setPath(this.hoverLine, [this.rulerPoints[this.rulerPoints.length - 1], p]);
        this.addDistanceText(this.hoverLine.path, 'hover', 'Text');
    }

    rulerHoverHandler(p) {
        if (!this.rulerPaused) {
            $j('.hoverText', this.mapContext).show();
            if (isSmartPhone || isSmartPhoneBrowser) {
                p = this.map.getCentre();
            }
            if (this.hoverPoint) {
                this.hoverPoint.lat = p.lat;
                this.hoverPoint.lon = p.lon;
            } else {
                this.createHoverpoint(p);
            }
            if (this.rulerPoints.length) {
                if (this.hoverLine) {
                    pwMap.Polyline.setPath(this.hoverLine, [this.rulerPoints[this.rulerPoints.length - 1], p]);
                    this.updateDistanceTextPosition([this.hoverLine.path[0], p], 'hover', 'Text');
                    this.updateHoverDistance();
                } else {
                    this.createHoverline(p);
                }
            }
        }
    }

    polylineDividedHoverHandler(p) {
        if (!this.activeHoverPoint) return;
        let index = this.rulerPointKeys.indexOf(this.activeHoverPoint);
        this.rulerPoints[index].lat = p.lat;
        this.rulerPoints[index].lon = p.lon;
        this.polyline.path[index] = p;
        pwMap.Polyline.setPath(this.polyline, this.polyline.path);
        this.updateDistanceText(this.rulerPointKeys[index], index);
        this.updateTotalDistance();
    }

    handleKeyDown(event) {
        if (event.key === "Enter") {
            this.pauseRuler();
        }
    }

    pauseRuler() {
        this.rulerPaused = true;
        this.disableHover();
        $j('pw-map-ruler-button', this.pageContext).prop({ 'showEndPrompt': false })
    }

    disableHover() {
        this.map._atlasMap.removeListener('move', this.rulerHoverHandler);
        this.map._atlasMap.removeListener('move', this.polylineDividedHoverHandler);
        if (this.hoverLine) {
            pwMap.LayerGroup.removeItem(this.mapRulerLayerGroup, this.hoverLine);
            this.hoverLine = null;
        }
        if (this.hoverPoint) {
            pwMap.LayerGroup.removeItem(this.mapRulerLayerGroup, this.hoverPoint);
            this.hoverPoint = null;
        }
        $j('.hoverText', this.mapContext).remove();
        this.activeHoverPoint = null;
        this.updateHoverDistance();
    }

    enable(p) {
        if (!this.enabled) {
            this.enabled = true;
            this.map._atlasMap.cursor = 'pointer';
            this.map._atlasMap.tooltipsEnabled = false;
            if (!(isSmartPhone || isSmartPhoneBrowser)) {
                this.map._atlasMap.addListener('click', this.createPoint);
                this.map._atlasMap.addListener('move', this.rulerHoverHandler);
                $j(document).on('keydown', this.handleKeyDown);
            } else {
                this.map.viewportHandler.add(() => {
                    this.rulerHoverHandler();
                })
            }
            this.map.addItem(this.mapRulerLayerGroup);
            this.createPoint(p);
            if (isSmartPhone || isSmartPhoneBrowser) {
                this.createHoverpoint(this.map.getCentre());
                this.createHoverline(this.map.getCentre());
                this.updateHoverDistance();
            }
        }
    }

    disable() {
        if (this.enabled) {
            this.enabled = false;
            this.map._atlasMap.cursor = 'auto';
            this.map._atlasMap.tooltipsEnabled = true;
            if (!(isSmartPhone || isSmartPhoneBrowser)) {
                this.map._atlasMap.removeListener('click', this.createPoint);
                $j(document).off('keydown', this.handleKeyDown);
            }
            this.disableHover();
            for (var i = this.rulerPointKeys.length - 1; i >= 0; i--) {
                this.removePoint(this.rulerPointKeys[i]);
            }
            this.map.removeItem(this.mapRulerLayerGroup);
            this.polyline = null;
            $j('pw-map-ruler-button', this.pageContext).prop({ 'distance': 0, 'tempDistance': 0 });
        }
    }
}